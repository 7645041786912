const DENOMINATIONS = [
  { id: '1', name: '$1' },
  { id: '2', name: '$2' },
  { id: '5', name: '$5' },
  { id: '10', name: '$10' },
  { id: '20', name: '$20' },
  { id: '50', name: '$50' },
  { id: '100', name: '$100' },
  { id: 'other', name: 'Other' },
];

// eslint-disable-next-line
export { DENOMINATIONS as default };
