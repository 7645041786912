/* eslint-disable react/jsx-fragments */
/* eslint-disable react/jsx-no-useless-fragment */
import parse from 'html-react-parser';

const faqs = [
  {
    id: 0,
    question: 'What is happening here?',
    answer: 'Paper dollars have serial numbers. Those numbers are tied to the robot avatars you can mint on this site. Much like an NFT, the owner of that paper money can "claim ownership" of that avatar because they possess the paper money with the serial number. If someone wants to collect an avatar, they need to buy the paper money - likely for more than the face value of the money. It is similar to how NFTs work, but, with non-crypto currencies.',
  },
  {
    id: 1,
    question: 'Why did you make this?',
    answer: 'Trying to get a 16 inch M1 Macbook Pro any way I can. Speaking of... have I mentioned my donate link? It\'s <a style="text-decoration: underline" href="https://www.buymeacoffee.com/nfdmiami">here</a>',
  },
  {
    id: 2,
    question: 'Why NFDs and not NFTs?',
    answer: 'Have both!  Although... some digital currencies may soon go out of fashion. However, there are over 50 billion printed paper dollar notes in circulation.  They are not going away.',
  },
  {
    id: 3,
    question: 'Are there duplicate art works?',
    answer: 'The serial number on your dollar is tied directly to one piece of art.  That piece of art may also be tied to a different serial number.',
  },
  {
    id: 4,
    question: 'Is this real?',
    answer: 'It is literally even more real than NFTs.',
  },
  {
    id: 5,
    question: 'This doesn\'t make any sense...',
    answer: 'This makes every bit as much sense as NFTs make',
  },
  {
    id: 6,
    question: 'Did you make all this yourself?',
    answer: 'Not quite. Big thanks to: Pablo Stanley & the Bottts project, Dicebear, js-confetti, and blobs (on npm).',
  },
  {
    id: 7,
    question: 'What happens when you mint an NFD?',
    answer: 'The serial you enter is used as a seed to generate an avatar. The same seed will always generate the same avatar.',
  },
  {
    id: 8,
    question: 'Can\'t I just get these avatars anywhere?',
    answer: 'Not with these special light green backgrounds.',
  },
  // {
  //   id: 4,
  //   question: 'Whats going on with seed 123423423423232?',
  //   answer: 'I wish I knew...',
  // },

  // More questions...
];

export default function FAQs() {
  return (
    <div className="bg-white" id="faqs">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-extrabold text-gray-900 text-center">Frequently Asked Questions</h2>
        <div className="mt-12">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-12 lg:grid-cols-3">
            {faqs.map((faq) => (
              <div key={faq.id}>
                <dt className="text-lg leading-6 font-medium text-gray-900">{faq.question}</dt>
                <dd className="mt-2 text-base text-gray-500">{parse(faq.answer)}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
