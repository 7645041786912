/* eslint-disable no-unreachable */
import { useState } from 'react';
// eslint-disable-next-line no-unused-vars
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';
import { getAnalytics, logEvent } from 'firebase/analytics';

export default function Sell() {
  const functions = getFunctions();

  // let updateFromEbay;
  // if (process.env.NODE_ENV === 'development') {
  //   connectFunctionsEmulator(functions, 'localhost', 5001);
  //   updateFromEbay = httpsCallable(functions, 'updateFromEbay');
  // }
  const submitNFD = httpsCallable(functions, 'submitNFD');

  const [link, setLink] = useState('');
  const [serial, setSerial] = useState('');
  const [denomination, setDenomination] = useState('1');
  const [error, setError] = useState('');
  const [waitingForSubmit, setWaitingForSubmit] = useState(false);

  const [success, setSuccess] = useState(false);

  const submitForm = (event) => {
    event.preventDefault();

    // if (process.env.NODE_ENV === 'development') {
    //   updateFromEbay();
    //   return;
    // }

    // Validate data
    if (!link) {
      setError('Please enter a link to your NFD');
      return;
    }

    if (!serial) {
      setError('Please enter a serial number');
      return;
    }

    // eslint-disable-next-line
    const urlMatcher = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
    if (!link.match(urlMatcher) || link.indexOf('ebay') < 0) {
      setError('Please enter a valid eBay link to your NFD');
      return;
    }

    if (serial.length < 8) {
      setError('Please enter a valid 8+ serial number');
      return;
    }

    submitNFD({
      link,
      serial,
      denomination,
    })
      .then(() => {
        setLink('');
        setSerial('');
        setDenomination('');
        setError('');
        setSuccess(true);
        setWaitingForSubmit(false);
      })
      .catch((e) => {
        setWaitingForSubmit(false);
        setError(e.message);
      });
    logEvent(getAnalytics(), 'submit_nfd');
  };

  return (
    <div className="bg-white py-16 sm:py-24" id="sell">
      <div className="relative sm:py-16">
        <div aria-hidden="true" className="hidden sm:block">
          <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50 rounded-r-3xl" />
          <svg className="absolute top-8 left-1/2 -ml-3" width={404} height={392} fill="none" viewBox="0 0 404 392">
            <defs>
              <pattern
                id="8228f071-bcee-4ec8-905a-2a059a2cc4fb"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={392} fill="url(#8228f071-bcee-4ec8-905a-2a059a2cc4fb)" />
          </svg>
        </div>

        <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
          <div className="relative rounded-2xl px-6 py-10 bg-emerald-600 overflow-hidden shadow-xl sm:px-12 sm:py-20">
            <div aria-hidden="true" className="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0">
              <svg
                className="absolute inset-0 h-full w-full"
                preserveAspectRatio="xMidYMid slice"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 1463 360"
              >
                <path
                  className="text-emerald-500 text-opacity-40"
                  fill="currentColor"
                  d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z"
                />
                <path
                  className="text-emerald-700 text-opacity-40"
                  fill="currentColor"
                  d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z"
                />
              </svg>
            </div>
            {
              success ? (
                <div className="text-center">
                  <h1 className="text-3xl font-bold text-white">
                    Your NFD has been successfully submitted!
                  </h1>
                  <p className="text-lg text-white">
                    We will review it and it will appear on the site once approved
                  </p>
                </div>
              ) : (
                <div className="relative">
                  <div className="sm:text-center">
                    <h2 className="text-3xl font-extrabold text-white tracking-tight sm:text-4xl">
                      Sell your NFDs
                    </h2>
                    <p className="mt-6 mx-auto max-w-2xl text-lg text-emerald-200">
                      Tell sell NFDs, start a listing on eBay and submit it here
                    </p>
                  </div>
                  <form action="#" className="mt-12 sm:mx-auto sm:max-w-lg ">
                    <div className="min-w-0 flex-1">
                      <label htmlFor="cta-email" className="sr-only">
                        eBay Link
                      </label>
                      <input
                        value={link}
                        onChange={(e) => { setLink(e.target.value); }}
                        className="block w-full border border-transparent rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-emerald-600"
                        placeholder="Enter your eBay Link"
                      />
                    </div>
                    <div className="min-w-0 flex-1 mt-4">
                      <label htmlFor="cta-email" className="sr-only">
                        Serial Number
                      </label>
                      <input
                        value={serial}
                        onChange={(e) => setSerial(e.target.value)}
                        className="block w-full border border-transparent rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-emerald-600"
                        placeholder="Serial Number - eg PB51488608A"
                      />
                    </div>
                    <div className="min-w-0 flex-1 mt-4">
                      <label htmlFor="cta-email" className="sr-only">
                        Denomination
                      </label>
                      <select
                        name="denomination"
                        className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm rounded-md"
                        defaultValue="Canada"
                        onChange={(e) => setDenomination(e.target.value)}
                        value={denomination}
                      >
                        <option value="1">$1</option>
                        <option value="2">$2</option>
                        <option value="5">$5</option>
                        <option value="10">$10</option>
                        <option value="20">$20</option>
                        <option value="50">$50</option>
                        <option value="100">$100</option>
                        <option value="other">Other</option>
                      </select>
                    </div>
                    <div className="mt-4 sm:mt-0 mt-4">
                      <button
                        type="submit"
                        onClick={(e) => {
                          e.preventDefault();
                          setWaitingForSubmit(true);
                          submitForm(e);
                        }}
                        disabled={waitingForSubmit}
                        className="mt-4 block w-full rounded-md border border-transparent px-5 py-3 bg-emerald-500 text-base font-medium text-white shadow hover:bg-emerald-400 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-emerald-600 sm:px-10"
                      >
                        {
                          waitingForSubmit ? (
                            <svg className="animate-spin mx-auto h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                            </svg>
                          ) : (
                            <span>Submit for sale</span>
                          )
                        }
                      </button>
                      <p className="mt-4 text-white">{error && 'Error: '}{error}</p>
                    </div>
                  </form>
                </div>
              )
            }

          </div>
        </div>
      </div>
    </div>
  );
}
