import React, { useState, useEffect } from 'react';
// import ReactDOM from "react-dom";
import { useSpring, animated } from 'react-spring';
// import "./styles.css";
import blobs from 'blobs';

const options = {
  complexity: 0.9,
  contrast: 0.6,
  guides: true,
  size: 1200,
  color: 'hsl(100.13, 73.23%, 49.8%)',
  stroke: 0,
};

function Background({ svg }) {
  return (
    <div style={{ position: 'relative' }}>
      <div style={{
        position: 'absolute', color: 'white', fontWeight: 700, fontSize: 46, left: 137, top: 113, userSelect: 'none',
      }}
      >?
      </div>
      <svg viewBox="0 0 1200 1200" className="wheel" width="300">
        {/* <defs>
          <radialGradient id="radial-gradient" cx="10%" fx="10%" fr="0%" r="80%">
            <stop offset="20%" stopColor="#5568C3" />
            <stop offset="95%" stopColor="#27336F" />
          </radialGradient>
        </defs> */}
        <g transform="translate(0,0)">
          {/* style={{ fill: 'url(#radial-gradient)' }} */}
          <animated.path d={svg} />
        </g>
      </svg>
    </div>
  );
}

export default function Blob() {
  const [blob, change] = useState(blobs.editable(options));
  const [intervalDisabled, setIntervalDisabled] = useState(false);
  // const [restoreInterval, setRestoreInterval] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (intervalDisabled) {
        return;
      }
      change(blobs.editable(options));
    }, 800);
    return () => clearInterval(interval);
  }, [intervalDisabled]);

  const props3 = useSpring({
    svg: blob.children[0].children[0].attributes.d,
    fill: blob.children[0].children[0].attributes.fill,
  });

  return (
    <div
      onClick={() => change(blobs.editable(options))}
      onMouseLeave={() => { change(blobs.editable(options)); setIntervalDisabled(false); }}
      onMouseEnter={() => { setIntervalDisabled(true); }}
    >
      <Background svg={props3.svg} fill={props3.fill} />
    </div>
  );
}
