import { useState, useEffect } from 'react';
import {
  getFirestore, collection, query, where, getDocs,
} from 'firebase/firestore';

import { getAnalytics, logEvent } from 'firebase/analytics';
import { sortBy, reverse } from 'lodash';

import Card from './Card';
import SelectBox from './SelectBox';

export default function Purchase() {
  const [nfds, setNfds] = useState([]);
  const db = getFirestore();

  const chooseDenomination = async (newDenomination) => {
    const q = query(collection(db, 'nfds'), where('denomination', '==', newDenomination.id), where('approved', '==', true));

    const querySnapshot = await getDocs(q);

    const newNFDs = [];
    querySnapshot.forEach((doc) => {
      newNFDs.push({
        id: doc.id,
        ...doc.data(),
      });
    });

    setNfds(reverse(sortBy(newNFDs, 'ended')));

    logEvent(getAnalytics(), 'select_denomination');
  };

  useEffect(() => {
    chooseDenomination({ id: '1' });
  }, []);

  return (
    <div id="purchase" className="max-w-7xl mx-auto sm:px-6 lg:px-8 mt-24">
      <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
        Buy and sell dollars for more than face value!
      </h2>
      <h3 className="text-center text-xs leading-8 font-extralight tracking-tight text-gray-900 sm:text-xs">Prices refreshed hourly</h3>

      <SelectBox chooseDenomination={chooseDenomination} />

      <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mt-10">
        {nfds.map((nfd) => (
          <Card key={nfd.id} {...nfd} />
        ))}
      </ul>
    </div>
  );
}
