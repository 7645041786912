export default function ArtDefinition() {
  return (
    <div className="mt-24 max-w-7xl mx-auto sm:px-6 lg:px-8 text-center">
      <p className=" text-xl text-gray-500 sm:text-center">
        art: (noun) something designed to evoke emotion
      </p>
      <h2 className="text-3xl font-extrabold text-gray-900">If NFTs made you feel something (excitement, annoyance...), then NFDs probably will too (confusion, amusement)</h2>

    </div>
  );
}
