import { useEffect, useState } from 'react';
import { CurrencyDollarIcon } from '@heroicons/react/outline';
import { createAvatar } from '@dicebear/avatars';
import * as style from '@dicebear/avatars-bottts-sprites';

export default function Card({
  serial, link, price, ended,
}) {
  const [userNFD, setUserNFD] = useState(null);

  useEffect(() => {
    const svg = createAvatar(style, {
      backgroundColor: '#d1fae5',
      seed: serial,
      dataUri: true,
      w: 256,
      h: 256,
    });

    setUserNFD(svg);
  }, []);

  return (
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
      className="bg-grey"
    >
      <li
        style={{ backgroundColor: `${ended ? '#f3f4f6' : ''}` }}
        className="col-span-1 flex flex-col text-center bg-grey rounded-lg shadow divide-y divide-gray-200"
      >
        <div className="flex-1 flex flex-col p-8">
          <img className="w-32 h-32 flex-shrink-0 mx-auto rounded-full" src={userNFD} alt="" />
          {
            ended ? (
              <h2 className="mt-6 text-gray-900 text-xl font-medium">AUCTION ENDED</h2>
            ) : (

              <h2 className="mt-6 text-gray-900 text-xl font-medium">{price}</h2>
            )
          }
          <dl className="mt-3 flex-grow flex flex-col justify-between">
            <dd>
              <div className="text-gray-500 text-sm">Serial number:</div>
              <span className="px-2 py-1 text-green-800 text-xs font-medium bg-green-100 rounded-full">
                {serial}
              </span>
            </dd>
          </dl>
        </div>
        <div>
          <div className="-mt-px flex divide-x divide-gray-200">
            <div className="w-0 flex-1 flex">
              <span className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500">

                <CurrencyDollarIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
                <span className="ml-3">Bid</span>

              </span>

            </div>
          </div>
        </div>
      </li>
    </a>
  );
}
