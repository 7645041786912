import React from 'react';
import './App.css';

import ArtDefinition from './ArtDefinition';
import FAQs from './FAQs';
import Footer from './Footer';
import Hero from './Hero';
import Mint from './Mint';
import Purchase from './Purchase';
import Security from './Security';
import Sell from './Sell';
// import Tagline from './Tagline';

export default function App() {
  return (
    <div>
      <Hero />
      <Mint />
      <Purchase />
      {/* <Tagline /> */}
      <ArtDefinition />
      <Sell />
      <FAQs />
      <Footer />
      <Security />
    </div>
  );
}
