import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { getAuth, signInAnonymously } from 'firebase/auth';

import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import App from './App';
import reportWebVitals from './reportWebVitals';

const firebaseConfig = {
  apiKey: 'AIzaSyCArg4qka_cDbCQxjOPHtFisBhWXMKzYHs',
  authDomain: 'nfds-f75dc.firebaseapp.com',
  projectId: 'nfds-f75dc',
  storageBucket: 'nfds-f75dc.appspot.com',
  messagingSenderId: '366469449163',
  appId: '1:366469449163:web:0c1e4f6d9afc2e8cf7624e',
  measurementId: 'G-S1RBS2VM4Q',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

logEvent(analytics, 'page_view');
const auth = getAuth();
signInAnonymously(auth);
// eslint-disable-next-line no-restricted-globals
// self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6LeB2OodAAAAAEflX2reh_aLH-Rf1LPuowVbaS6Z'),
  isTokenAutoRefreshEnabled: true,
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
