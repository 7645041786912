/* This example requires Tailwind CSS v2.0+ */
import { useState } from 'react';

import { createAvatar } from '@dicebear/avatars';
import * as style from '@dicebear/avatars-bottts-sprites';
import { getAnalytics, logEvent } from 'firebase/analytics';
import JSConfetti from 'js-confetti';

import Blob from './Blob';

const jsConfetti = new JSConfetti();

export default function Mint() {
  const [userSeed, setUserSeed] = useState('');
  const [userNFD, setUserNFD] = useState('');

  const updateSeed = (e) => {
    setUserSeed(e.target.value);
  };

  const mintAvatar = () => {
    const svg = createAvatar(style, {
      seed: userSeed,
      backgroundColor: '#d1fae5',
      dataUri: true,
      w: 256,
      h: 256,
    });

    setUserNFD(svg);

    jsConfetti.addConfetti({
      emojis: ['🌈', '⚡️', '💥', '✨', '💫', '🌸', '💰', '💸'],
      confettiRadius: 10,
      confettiNumber: 100,
      emojiSize: 150,
    });
    logEvent(getAnalytics(), 'generate_avatar');
  };

  const downloadNFD = () => {
    const svg = createAvatar(style, {
      seed: userSeed,
      size: 512,
      dataUri: true,
    });

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    canvas.width = 512;
    canvas.height = 512;

    context.fillStyle = '#d1fae5';
    context.fillRect(0, 0, canvas.width, canvas.height);

    const img = new Image();
    img.onload = () => {
      context.drawImage(img, 0, 0); // Or at whatever offset you like

      const png = canvas.toDataURL('image/png');

      const link = document.createElement('a');
      link.download = `${userSeed}.png`;
      link.href = png;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    img.src = svg;
  };

  return (
    <div className="bg-gray-900 mt-24" id="mint">
      <div className="pt-12 px-4 sm:px-6 lg:px-8 lg:pt-20">
        <div className="text-center">
          <h2 className="text-lg leading-6 font-semibold text-gray-300 uppercase tracking-wider">Minting</h2>
          <p className="mt-2 text-3xl font-extrabold text-white sm:text-4xl lg:text-5xl">
            Mint your own NFD *right now*
          </p>
          <p className="mt-3 max-w-4xl mx-auto text-xl text-gray-300 sm:mt-5 sm:text-2xl">
            Minting is easy. Just enter the serial number from your paper dollars below.
          </p>
        </div>
      </div>

      <div className="mt-16 bg-white pb-12 lg:mt-20 lg:pb-20">
        <div className="relative z-0">
          <div className="absolute inset-0 h-5/6 bg-gray-900 lg:h-2/3" />
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="relative lg:grid lg:grid-cols-7">
              <div className="mt-10 max-w-lg mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-start-3 lg:col-end-6 lg:row-start-1 lg:row-end-4">
                <div className="relative z-10 rounded-lg shadow-xl">
                  <div
                    className="pointer-events-none absolute inset-0 rounded-lg border-2 border-emerald-600"
                    aria-hidden="true"
                  />
                  <div className="absolute inset-x-0 top-0 transform translate-y-px">
                    <div className="flex justify-center transform -translate-y-1/2">
                      <span className="inline-flex rounded-full bg-emerald-600 px-4 py-1 text-sm font-semibold tracking-wider uppercase text-white">
                        Mint Now
                      </span>
                    </div>
                  </div>
                  <div className="bg-white rounded-t-lg px-6 pt-12 pb-10">
                    <div>
                      <h3 className="text-center text-3xl font-semibold text-gray-900 sm:-mx-6" id="tier-growth">
                        How to mint
                      </h3>
                      <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-lg lg:mx-0">
                        Type the serial number from any paper money in the box below and press the Mint button.  It's free and takes less than 5 seconds.
                      </p>
                      <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-lg lg:mx-0">
                        Use letters and numbers, and don't include any spaces.
                      </p>
                      <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-lg lg:mx-0">
                        OR, literally enter any text at all.  Trust me, you'll enjoy it. Just press the mint button.
                      </p>

                    </div>
                  </div>
                  <div className="border-t-2 border-gray-100  content-center rounded-b-lg pt-10 pb-8 px-6 bg-gray-50 sm:px-10 sm:py-10">
                    <div>
                      {
                        userNFD
                          ? (
                            <div className="grid place-items-center">
                              <img width="256" height="256" className="object-center" src={userNFD} />
                            </div>
                          )
                          : <div className="grid place-items-center"><Blob /></div>
                      }
                    </div>

                    <div>
                      <label htmlFor="account-number" className="block text-sm font-medium text-gray-700">
                        Serial number
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          onChange={updateSeed}
                          name="account-number"
                          id="account-number"
                          className="focus:ring-emerald-500 focus:border-emerald-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md"
                          placeholder="PB51488608A"
                        />
                        {/* <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                          <QuestionMarkCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </div> */}
                      </div>
                    </div>

                    <button
                      type="submit"
                      onClick={mintAvatar}
                      disabled={userSeed.length === 0}
                      className="mt-4 block w-full rounded-md border border-transparent px-5 py-3 bg-emerald-600 text-base font-medium text-white shadow hover:bg-emerald-400 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-emerald-600 sm:px-10"
                    >
                      Mint
                    </button>

                    {userNFD && (
                      <button
                        type="submit"
                        onClick={downloadNFD}
                        className="mt-4 block w-full rounded-md border border-transparent px-5 py-3 bg-emerald-500 text-base font-medium text-white shadow hover:bg-emerald-400 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-emerald-600 sm:px-10"
                      >
                        Download
                      </button>
                    )}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
